import {
  FpjsClient,
  FingerprintJSPro,
} from "@fingerprintjs/fingerprintjs-pro-spa"

export default defineNuxtPlugin((nuxtApp) => {
  const fpjsClient = new FpjsClient({
    loadOptions: {
      apiKey: "30vbXa4TkwjigUBBJ54s",
      endpoint: [
        // "https://metrics.yourwebsite.com",
        FingerprintJSPro.defaultEndpoint,
      ],
      scriptUrlPattern: [
        // "https://metrics.yourwebsite.com/web/v<version>/<apiKey>/loader_v<loaderVersion>.js",
        FingerprintJSPro.defaultScriptUrlPattern,
      ],
    },
  })

  nuxtApp.vueApp.provide("fpjsClient", fpjsClient)
  nuxtApp.provide("fpjsClient", fpjsClient)
})
